import { SusiLightWrapper, SusiLightWrapperProps } from './SusiLightWrapper'
import { ActionButton, Dialog, DialogBase, Icon } from 'ui'
import { useAuth } from '@hooks/useAuth'
import CrossIcon from '/public/s2_icon_cross.svg'
import AdobeIMS from '@services/auth/IMS'

export const SusiLightDialog = () => {
  const {
    showSignUpSignInModal,
    setShowSignUpSignInModal,
    setImsServiceReady,
    setStatus
  } = useAuth()

  const handleSusiToken: SusiLightWrapperProps['onSusiToken'] = async ({
    detail: token
  }): Promise<void> => {
    closeDialog()
    setStatus('CHECKING_STATUS')
    await AdobeIMS.refreshToken()
    setImsServiceReady()
  }

  const handleSusiError = (...args: any[]): void => {
    console.log({ args }, 'received args in susi error handler')
    closeDialog()
    setStatus('ERROR_CHECKING_STATUS')
  }

  const handleSusiLoad: SusiLightWrapperProps['onSusiLoad'] = ({ detail }) => {
    console.log({ detail }, 'susi onload handler')
  }

  const closeDialog = () => setShowSignUpSignInModal(false)

  return (
    <DialogBase
      close={closeDialog}
      open={showSignUpSignInModal}
      underlay
      dismissable>
      <Dialog noDivider style={{ height: 400, position: 'relative' }} size="m">
        <div style={{ position: 'absolute', right: 20, top: 20 }}>
          <ActionButton
            onClick={closeDialog}
            quiet
            size="s"
            variant="black"
            slot="trigger">
            <Icon slot="icon">
              <CrossIcon />
            </Icon>
          </ActionButton>
        </div>
        <SusiLightWrapper
          onSusiToken={handleSusiToken}
          onSusiError={handleSusiError}
          onSusiLoad={handleSusiLoad}
        />
      </Dialog>
    </DialogBase>
  )
}
