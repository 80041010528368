import { useProjectState } from '@hooks/useProject'
import { useSceneActions } from '@hooks/useScene'
import { EngineCommitChange, ValueAndRange } from '@services/engine/types'
import { PropertyPayload, SceneState } from '@store/slices/sceneSlice'
import { useEffect, useState } from 'react'
import { Slider } from 'ui'

// linear bijection from a closed interval [a, b] onto another closed interval [c, d]
export function closedIntervalOntoClosedInterval(
  a: number,
  b: number,
  c: number,
  d: number,
  x: number
) {
  return ((d - c) / (b - a)) * x + (c * b - a * d) / (b - a)
}

type ValueAndRangePayload = Pick<PropertyPayload, 'key' | 'value' | 'commit'>

type Props = {
  label: string
  ariaLabel?: string
  valueKey: keyof SceneState
  boundedValue: ValueAndRange
  min: number
  max: number
}

// This slider will display a percentage and will be used to update document properties
export function PercentagePropertySlider({
  label,
  valueKey,
  boundedValue,
  min,
  max,
  ariaLabel
}: Props) {
  const [currentPrecentage, setCurrentPercentage] = useState<number>(0)
  const sizePreference = useProjectState('sizePreference')
  const { setPropertyState } = useSceneActions()
  const [prevBoundedValue, setPrevBoundedValue] =
    useState<ValueAndRange>(boundedValue)

  if (
    prevBoundedValue.value !== boundedValue.value ||
    prevBoundedValue.min !== boundedValue.min ||
    prevBoundedValue.max !== prevBoundedValue.max
  ) {
    setCurrentPercentage(
      closedIntervalOntoClosedInterval(
        boundedValue.min,
        boundedValue.max,
        min,
        max,
        boundedValue.value
      )
    )

    setPrevBoundedValue(boundedValue)
  }

  function updateState(e: any, commit?: EngineCommitChange) {
    const value =
      (e as unknown as { target: { value: number } }).target.value * 100

    const valueToSendToEngine = closedIntervalOntoClosedInterval(
      min,
      max,
      boundedValue.min,
      boundedValue.max,
      value
    )

    const payload: ValueAndRangePayload = {
      key: valueKey,
      value: {
        value: valueToSendToEngine,
        min: boundedValue.min,
        max: boundedValue.max
      },
      commit
    }

    setPropertyState(payload as PropertyPayload)
    setCurrentPercentage(value)
  }

  return (
    <Slider
      size={sizePreference}
      label={label}
      aria-label={ariaLabel}
      variant="filled"
      value={currentPrecentage / 100}
      min={min / 100}
      max={max / 100}
      step={0.01}
      editable
      onInput={e => updateState(e)}
      onMouseDown={e => updateState(e, EngineCommitChange.BEGIN_COMMIT)}
      onMouseUp={e => updateState(e, EngineCommitChange.END_COMMIT)}
      hideStepper
      format-options='{
        "style": "percent"
    }'
    />
  )
}
