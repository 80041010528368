import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ProjectCardState = {
  reportFormVisible: boolean
  projectUuidToReport: string | null
}

const initialState: ProjectCardState = {
  reportFormVisible: false,
  projectUuidToReport: null
}

export const projectCard = createSlice({
  name: 'userLibrary',
  initialState,
  reducers: {
    openReportForm: (state: ProjectCardState) => {
      state.reportFormVisible = true
    },
    closeReportForm: (state: ProjectCardState) => {
      state.reportFormVisible = false
    },
    setProjectUuidToReport: (
      state: ProjectCardState,
      { payload }: PayloadAction<ProjectCardState['projectUuidToReport']>
    ) => {
      state.projectUuidToReport = payload
    }
  }
})

export const { openReportForm, closeReportForm, setProjectUuidToReport } =
  projectCard.actions

export default projectCard.reducer
