import React, { FC, memo } from 'react'
import { ColorPicker, FieldLabel, NumberField, Slider, Switch } from 'ui'
import { EngineCommitChange, EngineMode } from '@services/engine/types'
import { useSceneState, useSceneActions } from '@hooks/useScene'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import ColorPickerWithTextfield from '@components/colorPickerWithTextfield/ColorPickerWithTextfield'
import { ElementSize } from '@store/slices/projectSlice'

interface Props {
  size: ElementSize
}

const StylesInputs: FC<Props> = ({ size }) => {
  const mode = useSceneState('mode')
  const pixelOutlineEnabled = useSceneState('pixelOutlineEnabled')
  const modeExpressiveOutline = useSceneState('modeExpressiveOutline')
  const modeOutlineColor = useSceneState('modeOutlineColor')
  const modePixelSize = useSceneState('modePixelSize')
  const modeIllustrativeLightTexture = useSceneState(
    'modeIllustrativeLightTexture'
  )
  const modeIllustrativeGlobalStrokeSize = useSceneState(
    'modeIllustrativeGlobalStrokeSize'
  )
  const modeIllustrativeFilterStrength = useSceneState(
    'modeIllustrativeFilterStrength'
  )
  const modeIllustrativeHighlightIntensity = useSceneState(
    'modeIllustrativeHighlightIntensity'
  )
  const modeIllustrativeAmbientOcclusionIntensity = useSceneState(
    'modeIllustrativeAmbientOcclusionIntensity'
  )
  const modeIllustrativeEdgeBlendStrength = useSceneState(
    'modeIllustrativeEdgeBlendStrength'
  )
  const modeIllustrativeOutlineEnabled = useSceneState(
    'modeIllustrativeOutlineEnabled'
  )
  const modeIllustrativeOutlineTolerance = useSceneState(
    'modeIllustrativeOutlineTolerance'
  )
  const modeIllustrativeHighlightColor = useSceneState(
    'modeIllustrativeHighlightColor'
  )
  const modeIllustrativeShadowColor = useSceneState(
    'modeIllustrativeShadowColor'
  )
  const modeIllustrativeSkyColor = useSceneState('modeIllustrativeSkyColor')
  const modeIllustrativeBounceColor = useSceneState(
    'modeIllustrativeBounceColor'
  )
  const modeIllustrativeOutlineColor = useSceneState(
    'modeIllustrativeOutlineColor'
  )

  const { setPropertyState, setIllustrativeOutlineEnabled } = useSceneActions()

  const showContent =
    mode === EngineMode.PIXEL ||
    mode === EngineMode.OUTLINE ||
    mode === EngineMode.ILLUSTRATIVE

  if (!showContent) return null
  return (
    <>
      {mode === EngineMode.PIXEL && (
        <>
          <Slider
            size={size}
            variant="filled"
            min={16}
            max={256}
            step={1}
            aria-label="Pixel size"
            label="Pixel size"
            value={modePixelSize}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modePixelSize',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modePixelSize',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modePixelSize',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <div style={{ padding: '6px 0' }}>
            <Switch
              size={size}
              checked={pixelOutlineEnabled}
              onClick={() =>
                setPropertyState({
                  key: 'pixelOutlineEnabled',
                  value: !pixelOutlineEnabled
                })
              }>
              Pixel outline
            </Switch>
          </div>

          {pixelOutlineEnabled && (
            <ColorPickerWithTextfield
              size={size}
              label="Pixel outline color"
              color={modeOutlineColor}
              onMouseDown={e =>
                setPropertyState({
                  key: 'modeOutlineColor',
                  value: e?.target?.color,
                  commit: EngineCommitChange.BEGIN_COMMIT
                })
              }
              onInput={e =>
                setPropertyState({
                  key: 'modeOutlineColor',
                  value: e?.target?.color
                })
              }
              onChange={e =>
                setPropertyState({
                  key: 'modeOutlineColor',
                  value: e?.target?.color,
                  commit: EngineCommitChange.END_COMMIT
                })
              }
            />
          )}
        </>
      )}
      {mode === EngineMode.ILLUSTRATIVE && (
        <>
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={7}
            step={1}
            aria-label="Illustrative light texture"
            label="Texture style"
            value={modeIllustrativeLightTexture}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeLightTexture',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeLightTexture',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeLightTexture',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label="Illustrative global stroke size"
            label="Global stroke size"
            value={modeIllustrativeGlobalStrokeSize}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeGlobalStrokeSize',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeGlobalStrokeSize',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeGlobalStrokeSize',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label="Illustrative filter strength"
            label="Edge breakup"
            value={modeIllustrativeFilterStrength}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeFilterStrength',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeFilterStrength',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeFilterStrength',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label="Illustrative highlight intensity"
            label="Key light intensity"
            value={modeIllustrativeHighlightIntensity}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeHighlightIntensity',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeHighlightIntensity',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeHighlightIntensity',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label="Illustrative ambient occlusion intensity"
            label="Ambient texture intensity"
            value={modeIllustrativeAmbientOcclusionIntensity}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeAmbientOcclusionIntensity',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeAmbientOcclusionIntensity',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeAmbientOcclusionIntensity',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label="Illustrative edge blend intensity"
            label="Blending Intensity"
            value={modeIllustrativeEdgeBlendStrength}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeEdgeBlendStrength',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeEdgeBlendStrength',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeEdgeBlendStrength',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />

          <Switch
            size={size}
            checked={modeIllustrativeOutlineEnabled}
            onClick={() =>
              setIllustrativeOutlineEnabled(!modeIllustrativeOutlineEnabled)
            }>
            Outline
          </Switch>

          {modeIllustrativeOutlineEnabled && (
            <PropertyInputSlider
              size={size}
              variant="filled"
              min={1}
              max={100}
              step={1}
              aria-label="Illustrative outline tolerance"
              label="Outline tolerance"
              value={modeIllustrativeOutlineTolerance}
              onInput={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'modeIllustrativeOutlineTolerance',
                  value
                })
              }}
              onMouseDown={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'modeIllustrativeOutlineTolerance',
                  value,
                  commit: EngineCommitChange.BEGIN_COMMIT
                })
              }}
              onMouseUp={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'modeIllustrativeOutlineTolerance',
                  value,
                  commit: EngineCommitChange.END_COMMIT
                })
              }}
              editable
              hideStepper
            />
          )}

          {modeIllustrativeOutlineEnabled && (
            <ColorPicker
              label="Outline color"
              color={modeIllustrativeOutlineColor}
              onMouseDown={e =>
                setPropertyState({
                  key: 'modeIllustrativeOutlineColor',
                  value: e?.target?.color,
                  commit: EngineCommitChange.BEGIN_COMMIT
                })
              }
              onInput={e =>
                setPropertyState({
                  key: 'modeIllustrativeOutlineColor',
                  value: e?.target?.color
                })
              }
              onChange={e =>
                setPropertyState({
                  key: 'modeIllustrativeOutlineColor',
                  value: e?.target?.color,
                  commit: EngineCommitChange.END_COMMIT
                })
              }
            />
          )}

          <ColorPickerWithTextfield
            size={size}
            label="Highlight"
            color={modeIllustrativeHighlightColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeIllustrativeHighlightColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeIllustrativeHighlightColor',
                value: e?.target?.color
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeIllustrativeHighlightColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
          />

          <ColorPickerWithTextfield
            size={size}
            label="Shadow"
            color={modeIllustrativeShadowColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeIllustrativeShadowColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeIllustrativeShadowColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeIllustrativeShadowColor',
                value: e?.target?.color
              })
            }
          />

          <ColorPickerWithTextfield
            size={size}
            label="Sky"
            color={modeIllustrativeSkyColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeIllustrativeSkyColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeIllustrativeSkyColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeIllustrativeSkyColor',
                value: e?.target?.color
              })
            }
          />

          <ColorPickerWithTextfield
            size={size}
            label="Bounce"
            color={modeIllustrativeBounceColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeIllustrativeBounceColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeIllustrativeBounceColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeIllustrativeBounceColor',
                value: e?.target?.color
              })
            }
          />
        </>
      )}
      {mode === EngineMode.OUTLINE && (
        <>
          <ColorPickerWithTextfield
            size={size}
            label="Outline color"
            color={modeOutlineColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeOutlineColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeOutlineColor',
                value: e?.target?.color
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeOutlineColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
          />

          <div
            className="flex align-center justify-between"
            style={{ padding: '6px 0' }}>
            <FieldLabel for="vector-outline-thickness">
              Outline thickness
            </FieldLabel>
            <NumberField
              size={size}
              min={0}
              max={8}
              step={1}
              id="vector-outline-thickness"
              value={modeExpressiveOutline}
              onChange={e => {
                if (isNaN(e.target.value)) {
                  e.target.value = modeExpressiveOutline
                  e.stopPropagation()
                } else {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'modeExpressiveOutline',
                    value,
                    commit: EngineCommitChange.END_COMMIT
                  })
                }
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default memo(
  StylesInputs,
  (prevProps, nextProps) => prevProps.size === nextProps.size
)
