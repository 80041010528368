import { useEffect } from 'react'
import { useGetProjectByUuidQuery } from '@store/graphql/__generated__/schema'
import { useProjectActions } from '@hooks/useProject'
import { useSceneActions } from '@hooks/useScene'
import { useRouter } from 'next/router'
import { captureException } from '@sentry/nextjs'

export const useInitializeProject = (uuid?: string) => {
  const { push } = useRouter()

  useGetProjectByUuidQuery({
    variables: { uuid: uuid || '' },
    skip: !uuid,
    onCompleted({ project }) {
      if (!project) return
      setProjectUuid(project.uuid!)
      setIsFeatured(project.featured!)
      setIsPublic(project.public!)
      setName(project.name!)
      setOwnerUserUuid(project.ownerUserUuid || null)
      setEngineState('INITIALIZE')
      loadProject({ requireAuth: true })
    },
    onError(error) {
      captureException(error.message)
      console.error(error)
      push('/discover')
    }
  })

  const {
    setProjectUuid,
    setIsFeatured,
    setName,
    setOwnerUserUuid,
    setIsPublic,
    loadProject
  } = useProjectActions()

  const { setEngineState } = useSceneActions()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])
}
