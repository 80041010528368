import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { firefly } from '@store/slices/fireflySlice'
import { EngineMode } from '@services/engine/types'

export const useFirefly = () => {
  const state = useSelector((state: RootState) => state.firefly)
  const { mode: sceneMode } = useSelector((state: RootState) => state.scene)
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(firefly.actions, dispatch)

  return {
    ...state,
    ...boundedActions,
    selectedGeneratedImage: state.generatedImages.find(
      ({ selected }) => selected
    )?.url,
    isSceneRealisticOrVectorArt:
      sceneMode === EngineMode.NORMAL || sceneMode === EngineMode.OUTLINE
  }
}

export type FireflyStateProperties = ReturnType<typeof useFirefly>
