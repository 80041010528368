import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  EngineCommitChange,
  PrimitiveDimensionType
} from '@services/engine/types'
import { ActionButton, ActionGroup, FieldLabel, Icon } from 'ui'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import PanelAccordion from '@components/panel/PanelAccordion'
import FlatShape from './FlatShape'
import ExtrudeIcon from '/public/s2_icon_3d_extrude.svg'
import RevolveIcon from '/public/s2_icon_3d_revolve.svg'
import { Section } from './ObjectProperties'

const VolumeShaping: FC = () => {
  const threeDVolumeShapeCollapsed = useProjectState(
    'threeDVolumeShapeCollapsed'
  )
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const { setPropertyState } = useSceneActions()

  const address = useSceneState('address')
  const primitiveDimensionType = useSceneState('primitiveDimensionType')
  const primitiveDistance = useSceneState('primitiveDistance')
  const primitiveRotation = useSceneState('primitiveRotation')
  const primitiveShell = useSceneState('primitiveShell')
  const corner1 = useSceneState('corner1')
  const corner2 = useSceneState('corner2')
  const inflate = useSceneState('inflate')

  const showCornerOne =
    primitiveDimensionType === PrimitiveDimensionType.EXTRUDE
  const showCornerTwo =
    primitiveDimensionType === PrimitiveDimensionType.EXTRUDE
  const showDistance = primitiveDimensionType === PrimitiveDimensionType.REVOLVE
  const showRotation = primitiveDimensionType === PrimitiveDimensionType.REVOLVE

  return (
    <PanelAccordion
      id={Section.shape}
      label="Shape"
      collapsed={threeDVolumeShapeCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDVolumeShapeCollapsed', collapsed })
      }>
      <div
        className="flex justify-between align-center"
        style={{ paddingTop: 4 }}>
        <FieldLabel>3D type</FieldLabel>
        <ActionGroup
          compact
          size={sizePreference}
          selects="single"
          selected={[primitiveDimensionType.toString()]}
          change={e => {
            const keys = (e as unknown as { target: { selected: string[] } })
              .target.selected
            if (!keys.length) return
            setPropertyState({
              address,
              key: 'primitiveDimensionType',
              value: Number(keys[0]) as PrimitiveDimensionType
            })
          }}>
          <ActionButton
            key={PrimitiveDimensionType.EXTRUDE.toString()}
            value={PrimitiveDimensionType.EXTRUDE.toString()}>
            <Icon slot="icon">
              <ExtrudeIcon />
            </Icon>
            Extrude
          </ActionButton>
          <ActionButton
            key={PrimitiveDimensionType.REVOLVE.toString()}
            value={PrimitiveDimensionType.REVOLVE.toString()}>
            <Icon slot="icon">
              <RevolveIcon />
            </Icon>
            Revolve
          </ActionButton>
        </ActionGroup>
      </div>

      {showDistance && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={0}
          max={0.2}
          step={0.001}
          aria-label="Distance"
          label="Distance"
          value={primitiveDistance}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveDistance',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveDistance',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveDistance',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}
      {showRotation && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={0}
          max={6.283}
          step={0.001}
          aria-label="Rotation"
          label="Rotation"
          value={primitiveRotation}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveRotation',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveRotation',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveRotation',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}
      {showCornerOne && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={corner1.min}
          max={corner1.max}
          aria-label="Bottom Corner"
          label="Bottom Corner"
          value={corner1.value}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveCornerOne',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveCornerOne',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveCornerOne',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}
      {showCornerTwo && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={corner2.min}
          max={corner2.max}
          aria-label="Top Corner"
          label="Top Corner"
          value={corner2.value}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveCornerTwo',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveCornerTwo',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveCornerTwo',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={0.2}
        step={0.001}
        aria-label="Inside Shell"
        label="Inside Shell"
        value={primitiveShell}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveShell',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveShell',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveShell',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={inflate.min}
        max={inflate.max}
        aria-label="Inflate"
        label="Inflate"
        value={inflate.value}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveRound',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveRound',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveRound',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />

      <FlatShape />
    </PanelAccordion>
  )
}

export default memo(VolumeShaping)
