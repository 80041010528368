import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '@store/graphql/__generated__/schema'

export interface AuthenticationState {
  status:
    | 'CHECKING_STATUS'
    | 'ERROR_CHECKING_STATUS'
    | 'AUTHENTICATED'
    | 'UNAUTHENTICATED'
  localUser: User | null
  launchDarklyContext: { key: string; email: string } | null
  onlineUsers: Record<string, {}>
  isAuthenticationStateLoaded: boolean
  showPrivateBetaSurveyToast: boolean
  showSignUpSignInModal: boolean
}

const initialState: AuthenticationState = {
  status: 'CHECKING_STATUS',
  onlineUsers: {},
  localUser: null,
  launchDarklyContext: null,
  isAuthenticationStateLoaded: false,
  showPrivateBetaSurveyToast: false,
  showSignUpSignInModal: false
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLocalUser: (state, { payload }: PayloadAction<User | null>) => {
      state.localUser = payload
    },
    setLaunchDarklyContext: (
      state,
      { payload }: PayloadAction<{ key: string; email: string } | null>
    ) => {
      state.launchDarklyContext = payload
    },
    setImsServiceReady: () => {},
    setStatus: (
      state,
      { payload }: PayloadAction<AuthenticationState['status']>
    ) => {
      state.status = payload
    },
    setOnlineUsers: (state, { payload }: PayloadAction<Record<string, {}>>) => {
      state.onlineUsers = payload
    },
    userLoaded: () => {},
    setIsAuthenticationStateLoaded: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAuthenticationStateLoaded = payload
    },
    logout: () => {},
    setShowPrivateBetaSurveyToast: (
      state,
      {
        payload
      }: PayloadAction<AuthenticationState['showPrivateBetaSurveyToast']>
    ) => {
      state.showPrivateBetaSurveyToast = payload
    },
    setShowSignUpSignInModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showSignUpSignInModal = payload
    },
    setProjectPrivateDefault: (state, { payload }: PayloadAction<boolean>) => {
      state.localUser = {
        ...state.localUser,
        preferences: {
          color: state.localUser?.preferences?.color,
          projectsPrivateDefault: payload
        }
      }
    },
    promptLogin: (
      _,
      { payload }: PayloadAction<{ onAuthenticated?: (...args: any[]) => void }>
    ) => {}
  }
})

export const {
  setImsServiceReady,
  setLocalUser,
  setOnlineUsers,
  setStatus,
  userLoaded,
  setLaunchDarklyContext,
  setIsAuthenticationStateLoaded,
  logout,
  setShowPrivateBetaSurveyToast,
  setShowSignUpSignInModal,
  setProjectPrivateDefault,
  promptLogin
} = auth.actions

export default auth.reducer
