import React, { FC, memo } from 'react'
import {
  SceneStateProperties,
  useSceneActions,
  useSceneState
} from '@hooks/useScene'
import { EngineBlendType, EngineCommitChange } from '@services/engine/types'
import { ActionButton, ActionGroup, FieldLabel } from 'ui'
import PanelAccordion from '@components/panel/PanelAccordion'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { Section } from './ObjectProperties'

const Combine: FC = () => {
  const threeDCombineCollapsed = useProjectState('threeDCombineCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const blendAmount = useSceneState('blendAmount')
  const blendType = useSceneState('blendType')
  const address = useSceneState('address')

  const { setPropertyState } = useSceneActions()

  return (
    <PanelAccordion
      id={Section.combine}
      label="Combine shapes"
      collapsed={threeDCombineCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDCombineCollapsed', collapsed })
      }>
      <FieldLabel>Additive</FieldLabel>

      <ActionGroup
        size={sizePreference}
        selects="single"
        selected={[blendType.toString()]}
        change={e => {
          const keys = (e as unknown as { target: { selected: string[] } })
            .target.selected
          if (!keys.length) return
          setPropertyState({
            address,
            key: 'blendType',
            value: Number(keys[0]) as SceneStateProperties['blendType']
          })
        }}>
        <ActionButton
          key={EngineBlendType.ADD.toString()}
          value={EngineBlendType.ADD.toString()}
          aria-label="Combination add">
          Add
        </ActionButton>

        <ActionButton
          key={EngineBlendType.COLOR.toString()}
          value={EngineBlendType.COLOR.toString()}
          aria-label="Combination color">
          Color
        </ActionButton>
        <ActionButton
          key={EngineBlendType.REPEL.toString()}
          value={EngineBlendType.REPEL.toString()}
          aria-label="Combination repel">
          Repel
        </ActionButton>
        <ActionButton
          key={EngineBlendType.AVOID.toString()}
          value={EngineBlendType.AVOID.toString()}
          aria-label="Combination avoid">
          Avoid
        </ActionButton>
      </ActionGroup>

      <FieldLabel>Subtractive</FieldLabel>

      <ActionGroup
        size={sizePreference}
        selects="single"
        selected={[blendType.toString()]}
        change={e => {
          const keys = (e as unknown as { target: { selected: string[] } })
            .target.selected
          if (!keys.length) return
          setPropertyState({
            address,
            key: 'blendType',
            value: Number(keys[0]) as SceneStateProperties['blendType']
          })
        }}>
        <ActionButton
          key={EngineBlendType.SUBTRACT.toString()}
          value={EngineBlendType.SUBTRACT.toString()}
          aria-label="Combination carve">
          Carve
        </ActionButton>
        <ActionButton
          key={EngineBlendType.INTERSECT.toString()}
          value={EngineBlendType.INTERSECT.toString()}
          aria-label="Combination intersect">
          Intersect
        </ActionButton>
      </ActionGroup>

      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        label="Blending"
        aria-label="Blending"
        value={blendAmount}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'blendAmount',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'blendAmount',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'blendAmount',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
    </PanelAccordion>
  )
}

export default memo(Combine)
