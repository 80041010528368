import {
  PropertyHasChangedData,
  ScenePrimType,
  MaterialProperties,
  IVec3,
  TextPrimitiveProperties,
  FontWeight,
  VariableColorModeValues
} from '@services/engine/types'
import { SceneState, setPropertiesState } from '@store/slices/sceneSlice'
import { call, put, select } from 'redux-saga/effects'
import Context from '@store/middleware/context'
import { radToDeg } from '@services/engine/utils'
import { RootState } from '@store/store'

export function* handlePropertyHasChanged(data: PropertyHasChangedData) {
  try {
    if (!Context.Engine) return

    const { path, panelId, value } = data

    const primitiveType: ScenePrimType = yield select(
      (state: RootState) => state.scene.primitiveType
    )

    /* 
    ----------------- Primitive paths -----------------
    ---------------------------------------------------
    ---------------------------------------------------
    */

    if (path === 'shapeMaterial.pbrColor') {
      yield setState('materialColor', engineRGBToHex(value))
    }

    if (path === 'shapeMaterial.pbrRoughness') {
      yield setState('materialRoughness', value)
    }

    if (path === 'shapeMaterial.pbrMetalness') {
      yield setState('materialMetalness', value)
    }

    if (path === 'shapeMaterial.pbrIsReflective') {
      yield setState('materialReflective', value)
    }

    if (path === 'treeNode.standard.position') {
      yield setMultipleStates({
        transformPositionX: value.x,
        transformPositionY: value.y,
        transformPositionZ: value.z
      })
    }

    if (path === 'treeNode.standard.rotation') {
      yield setMultipleStates({
        transformRotationX: radToDeg(value.x),
        transformRotationY: radToDeg(value.y),
        transformRotationZ: radToDeg(value.z)
      })
    }

    if (path === 'treeNode.standard.name') {
      yield setState('elementName', value)
    }

    if (path === 'treeNode.standard.blendMode') {
      yield setState('blendType', value)
    }

    if (path === 'treeNode.standard.blendShape') {
      yield setState('blendAmount', value)
    }

    if (path === 'treeNode.standard.repeatMode') {
      yield setState('repeatType', value)
    }

    if (path === 'treeNode.standard.repeatX') {
      yield setState('repeatX', value)
    }

    if (path === 'treeNode.standard.repeatY') {
      yield setState('repeatY', value)
    }

    if (path === 'treeNode.standard.repeatZ') {
      yield setState('repeatZ', value)
    }

    if (path === 'treeNode.standard.distanceX') {
      yield setState('repeatDistanceX', value)
    }

    if (path === 'treeNode.standard.distanceY') {
      yield setState('repeatDistanceY', value)
    }

    if (path === 'treeNode.standard.distanceZ') {
      yield setState('repeatDistanceZ', value)
    }

    if (path === 'treeNode.standard.circularCopyAxis') {
      yield setState('repeatAngleDirection', value)
    }

    if (path === 'treeNode.standard.circularCopyCount') {
      yield setState('repeatAngle', value)
    }

    if (path === 'treeNode.standard.circularCopyDistance') {
      yield setState('repeatDistance', value)
    }

    if (path === 'treeNode.standard.alongX') {
      yield setState('symmetryX', value)
    }

    if (path === 'treeNode.standard.alongY') {
      yield setState('symmetryY', value)
    }

    if (path === 'treeNode.standard.alongZ') {
      yield setState('symmetryZ', value)
    }

    if (path === 'treeNode.standard.onion') {
      yield setState('primitiveShell', value)
    }

    if (path === 'treeNode.standard.round') {
      const { min, max } = yield select(
        (state: RootState) => state.scene.inflate
      )
      yield setState('primitiveRound', value)
      yield setState('inflate', {
        value,
        min,
        max
      })
    }

    if (path === 'treeNode.standard.thickness') {
      const { min, max } = yield select((state: RootState) => state.scene.hole)
      yield setState('primitiveHole', value)
      yield setState('hole', {
        value,
        min,
        max
      })
    }

    if (path === 'treeNode.dimension.mode') {
      yield setState('primitiveDimensionType', value)
    }

    if (path === 'treeNode.dimension.extrudeAmount') {
      if (
        primitiveType === ScenePrimType.SOFT_BOX ||
        primitiveType === ScenePrimType.STAR ||
        primitiveType === ScenePrimType.TRIANGLE
      ) {
        yield setState('transformScaleY', value * 2)
      }

      if (primitiveType === ScenePrimType.HORSESHOE) {
        yield setState('transformScaleY', value)
      }
    }

    if (path === 'treeNode.dimension.bottomFillet') {
      const { min, max } = yield select(
        (state: RootState) => state.scene.corner1
      )
      yield setState('primitiveCornerOne', value)
      yield setState('corner1', {
        value,
        min,
        max
      })
    }

    if (path === 'treeNode.dimension.topFillet') {
      const { min, max } = yield select(
        (state: RootState) => state.scene.corner2
      )
      yield setState('primitiveCornerTwo', value)
      yield setState('corner2', {
        value,
        min,
        max
      })
    }

    if (path === 'treeNode.dimension.distance') {
      yield setState('primitiveDistance', value)
    }

    if (path === 'treeNode.dimension.rotation') {
      yield setState('primitiveRotation', value)
    }

    if (path === 'treeNode.size.width') {
      yield setState('transformScaleX', value)
    }

    if (path === 'treeNode.size.height') {
      if (primitiveType === ScenePrimType.TRIANGLE) {
        yield setState('transformScaleZ', value / 2)
      } else {
        yield setState('transformScaleZ', value)
      }
    }

    if (path === 'treeNode.cornerRounding0') {
      yield setState('primitiveRadial0', value)
    }

    if (path === 'treeNode.cornerRounding1') {
      yield setState('primitiveRadial1', value)
    }

    if (path === 'treeNode.cornerRounding2') {
      yield setState('primitiveRadial2', value)
    }

    if (path === 'treeNode.cornerRounding3') {
      yield setState('primitiveRadial3', value)
    }

    if (path === 'treeNode.angle') {
      if (primitiveType === ScenePrimType.STAR) {
        yield setState('primitiveStarAngle', value)
      }

      if (primitiveType === ScenePrimType.HORSESHOE) {
        yield setState('primitiveHorseshoeAngle', value)
      }

      if (primitiveType === ScenePrimType.TRIANGLE) {
        yield setState('primitiveHorseshoeAngle', value)
      }
    }

    if (path === 'treeNode.radius') {
      if (primitiveType === ScenePrimType.STAR) {
        yield setState('transformScaleX', value)
      }

      if (primitiveType === ScenePrimType.HORSESHOE) {
        yield setMultipleStates({
          primitiveHorseshoeRadius: value,
          transformScaleZ: value
        })
      }
    }

    if (path === 'treeNode.width') {
      if (primitiveType === ScenePrimType.STAR) {
        yield setState('transformScaleX', value)
      }

      if (primitiveType === ScenePrimType.HORSESHOE) {
        yield setState('primitiveHorseshoeWidth', value)
      }
    }

    if (path === 'treeNode.length') {
      if (primitiveType === ScenePrimType.HORSESHOE) {
        yield setState('primitiveHorseshoeLength', value)
      }
    }

    if (path === 'treeNode.thickness') {
      yield setState('primitiveHorseshoeThickness', value)
    }

    if (path === 'treeNode.corners') {
      yield setState('primitiveHorseshoeCorner', value)
    }

    if (path === 'treeNode.dartOffset') {
      const { min, max } = yield select(
        (state: RootState) => state.scene.triangleVertex
      )

      yield setState('triangleVertex', {
        value,
        min,
        max
      })
    }

    if (path === 'treeNode.sideCount') {
      yield setState('primitiveStarPoints', value)
    }

    if (path === 'treeNode.roundingRadius') {
      const { min, max } = yield select(
        (state: RootState) => state.scene.starCorners
      )
      yield setState('primitiveStarCorners', value)
      yield setState('starCorners', {
        value,
        min,
        max
      })
    }

    if (path === 'treeNode.radiusA') {
      yield setMultipleStates({
        transformScaleX: value
      })
    }

    if (path === 'treeNode.radiusB') {
      // value corresponding to treeNode.radiusB is twice the value corresponding to treeNode.radiusA
      yield setMultipleStates({
        transformScaleZ: value * 2
      })
    }

    if (path === 'shapeMaterial.exprMaterialMode') {
      const mode = VariableColorModeValues[value]
      yield setState('variableColorMode', mode ?? 0)
    }

    if (path === 'shapeMaterial.exprColorTop') {
      yield setState('materialEColorTop', engineRGBToHex(value))
    }

    if (path === 'shapeMaterial.exprColorLight') {
      yield setState('materialEColorLig', engineRGBToHex(value))
    }

    if (path === 'shapeMaterial.exprColorDark') {
      yield setState('materialEColorSha', engineRGBToHex(value))
    }

    if (path === 'shapeMaterial.exprSpecularSize') {
      yield setState('materialESpecularSize', value)
    }

    if (path === 'shapeMaterial.exprSpecularIntensity') {
      yield setState('materialESpecularIntensity', value)
    }

    if (path === 'shapeMaterial.illustrativeMaterialMode') {
      const mode = VariableColorModeValues[value]
      yield setState('variableColorMode', mode ?? 0)
    }

    if (path === 'shapeMaterial.illustrativeColor') {
      yield setState('materialIColor', engineRGBToHex(value))
    }

    if (path === 'shapeMaterial.illustrativeEmissiveColor') {
      yield setState('materialIEmissiveColor', engineRGBToHex(value))
    }

    if (path === 'shapeMaterial.illustrativeStrokeSize') {
      yield setState('materialIStrokeSize', value)
    }

    if (path === 'shapeMaterial.illustrativeSpecularIntensity') {
      yield setState('materialIHighlightIntensity', value)
    }

    if (path === 'shapeMaterial.illustrativeNormalIntensity') {
      yield setState('materialIStrokeIntensity', value)
    }

    if (path === 'shapeMaterial.illustrativeColorVariation') {
      yield setState('materialIColorVarIntensity', value)
    }

    if (path === 'shapeMaterial.illustrativeScaleX') {
      yield setState('materialIScaleX', value)
    }

    if (path === 'shapeMaterial.illustrativeScaleY') {
      yield setState('materialIScaleY', value)
    }

    if (path === 'shapeMaterial.illustrativeAngle') {
      yield setState('materialIAngle', value)
    }

    /* 
    ----------------- Global paths -----------------
    ---------------------------------------------------
    ---------------------------------------------------
    */

    if (path === 'cameraLens.distortionAmount') {
      yield setState('cameraDistortion', value)
    }

    if (path === 'cameraLens.dofAperture') {
      yield setState('cameraAperture', value)
    }

    if (path === 'cameraLens.dofFocalPlane') {
      yield setState('cameraFocalPlane', value)
    }

    if (path === 'cameraLens.perspFocalLength') {
      yield setState('cameraFocalLength', value)
    }

    if (path === 'cameraLens.orthoSize') {
      yield setState('cameraSize', value)
    }

    if (path === 'background.colorA') {
      yield setState('backgroundColorA', engineRGBToHex(value))
    }

    if (path === 'background.colorB') {
      yield setState('backgroundColorB', engineRGBToHex(value))
    }

    if (path === 'background.gradientMode') {
      yield setState('backgroundType', value)
    }

    if (path === 'frame.frameType') {
      yield setState('frameType', value)
    }

    if (path === 'frame.size') {
      yield setState('frameSize', { w: value.x, h: value.y })
    }

    if (path === 'frame.opacity') {
      yield setState('frameOpacity', value * 100) // multiply by 100 because the engine treats opacity as a value between 0 and 1
    }

    if (path === 'floor.enabled') {
      yield setState('floorEnabled', value)
    }

    if (path === 'floor.height') {
      yield setState('floorHeight', value)
    }

    if (path === 'frame.enabled') {
      yield setState('frameEnabled', value)
    }

    if (path === 'cameraLens.type') {
      yield setState('cameraType', value)
    }

    if (path === 'style.style') {
      // Add 1 from value because the engine returns a value that is 1 less than what it is on the JS side (this enum in the engine starts at 0, whereas on the JS side it starts at 1)
      yield setState('mode', value + 1)
    }

    if (path === 'style.exprOutline') {
      yield setState('modeExpressiveOutline', value)
    }

    if (path === 'style.exprColor') {
      yield setState('modeOutlineColor', engineRGBToHex(value))
    }

    if (path === 'style.pixelArtPixelCount') {
      yield setState('modePixelSize', value)
    }

    if (path === 'style.pixelArtFilter') {
      yield setState('pixelFilterEnabled', Boolean(value))
    }

    if (path === 'style.pixelOutlineEnabled') {
      yield setState('pixelOutlineEnabled', value)
    }

    if (path === 'style.illusLightTexture') {
      yield setState('modeIllustrativeLightTexture', value)
    }

    if (path === 'style.illusShadowTexture') {
      yield setState('modeIllustrativeShadowTexture', value)
    }

    if (path === 'style.illusGlobalStrokeSize') {
      yield setState('modeIllustrativeGlobalStrokeSize', value)
    }

    if (path === 'treeNode.text') {
      const textPrimitiveProperties: TextPrimitiveProperties = yield select(
        (state: RootState) => state.scene.textPrimitiveProperties
      )

      yield setState('textPrimitiveProperties', {
        ...textPrimitiveProperties,
        text: value
      })
    }

    if (path === 'treeNode.size') {
      const primitiveType: ScenePrimType = yield select(
        (state: RootState) => state.scene.primitiveType
      )

      if (primitiveType === ScenePrimType.TEXT) {
        const textPrimitiveProperties: TextPrimitiveProperties = yield select(
          (state: RootState) => state.scene.textPrimitiveProperties
        )

        yield setState('textPrimitiveProperties', {
          ...textPrimitiveProperties,
          size: value
        })
      }
    }

    if (path === 'treeNode.font') {
      const textPrimitiveProperties: TextPrimitiveProperties = yield select(
        (state: RootState) => state.scene.textPrimitiveProperties
      )

      yield setState('textPrimitiveProperties', {
        ...textPrimitiveProperties,
        font: value
      })
    }

    if (path === 'treeNode.weight') {
      const textPrimitiveProperties: TextPrimitiveProperties = yield select(
        (state: RootState) => state.scene.textPrimitiveProperties
      )

      yield setState('textPrimitiveProperties', {
        ...textPrimitiveProperties,
        weight: Context.Engine.engineWeightToFontWeight(value) as FontWeight
      })
    }

    if (path === 'treeNode.lineHeight') {
      const textPrimitiveProperties: TextPrimitiveProperties = yield select(
        (state: RootState) => state.scene.textPrimitiveProperties
      )

      yield setState('textPrimitiveProperties', {
        ...textPrimitiveProperties,
        lineHeight: value
      })
    }

    if (path === 'treeNode.letterSpacing') {
      const textPrimitiveProperties: TextPrimitiveProperties = yield select(
        (state: RootState) => state.scene.textPrimitiveProperties
      )

      yield setState('textPrimitiveProperties', {
        ...textPrimitiveProperties,
        letterSpacing: value
      })
    }
  } catch (err) {
    console.error(err)
  }
}

function* setState<T extends keyof SceneState>(key: T, value: SceneState[T]) {
  yield put(
    setPropertiesState({
      [key]: value
    })
  )
}

function* setMultipleStates(kvPair: Partial<SceneState>) {
  yield put(
    setPropertiesState({
      ...kvPair
    })
  )
}

function engineRGBToHex(rgb: IVec3): string {
  return Context.Engine!.rGBToHex(rgb.x, rgb.y, rgb.z)
}
