import { setPropertiesState } from '@store/slices/sceneSlice'
import { RootState } from '@store/store'
import { put, select } from 'redux-saga/effects'
import {
  PropertySoftBoundsHaveChangedData,
  ValueAndRange
} from '@services/engine/types'

export function* handlePropertySoftBoundsHaveChanged(
  data: PropertySoftBoundsHaveChangedData
) {
  const { path, min, max } = data

  if (path === 'treeNode.dimension.bottomFillet') {
    const value: number = yield select(
      (state: RootState) => state.scene.primitiveCornerOne
    )

    yield put(
      setPropertiesState({
        corner1: {
          value,
          min,
          max
        }
      })
    )
  }

  if (path === 'treeNode.dimension.topFillet') {
    const value: number = yield select(
      (state: RootState) => state.scene.primitiveCornerTwo
    )

    yield put(
      setPropertiesState({
        corner2: {
          value,
          min,
          max
        }
      })
    )
  }

  if (path === 'treeNode.standard.thickness') {
    const value: number = yield select(
      (state: RootState) => state.scene.primitiveHole
    )

    yield put(
      setPropertiesState({
        hole: {
          value,
          min,
          max
        }
      })
    )
  }

  if (path === 'treeNode.standard.round') {
    const value: number = yield select(
      (state: RootState) => state.scene.primitiveRound
    )

    yield put(
      setPropertiesState({
        inflate: {
          value,
          min,
          max
        }
      })
    )
  }

  if (path === 'treeNode.roundingRadius') {
    const value: number = yield select(
      (state: RootState) => state.scene.starCorners
    )

    yield put(
      setPropertiesState({
        starCorners: {
          value,
          min,
          max
        }
      })
    )
  }

  if (path === 'treeNode.dartOffset') {
    const { value: triangleVertexValue }: ValueAndRange = yield select(
      (state: RootState) => state.scene.triangleVertex
    )

    yield put(
      setPropertiesState({
        triangleVertex: {
          value: triangleVertexValue,
          min,
          max
        }
      })
    )
  }
}
