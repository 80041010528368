import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineCommitChange, ScenePrimType } from '@services/engine/types'
import { FieldLabel, NumberField, Slider } from 'ui'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { useProjectState } from '@hooks/useProject'
import { PercentagePropertySlider } from '@components/percentagePropertySlider/PercentagePropertySlider'

const SoftBoxInputs: FC = () => {
  const { setPropertyState } = useSceneActions()
  const address = useSceneState('address')
  const primitiveRadial0 = useSceneState('primitiveRadial0')
  const primitiveRadial1 = useSceneState('primitiveRadial1')
  const primitiveRadial2 = useSceneState('primitiveRadial2')
  const primitiveRadial3 = useSceneState('primitiveRadial3')

  const sizePreference = useProjectState('sizePreference')

  return (
    <div>
      <FieldLabel>Corners</FieldLabel>
      <div className="flex gap-s">
        <NumberField
          hideStepper
          size={sizePreference}
          min={0}
          step={0.001}
          aria-label="radial 0"
          value={primitiveRadial0}
          onChange={e => {
            setPropertyState({
              address,
              key: 'primitiveRadial0',
              value: e.target.value
            })
          }}
        />
        <NumberField
          hideStepper
          size={sizePreference}
          min={0}
          step={0.001}
          aria-label="radial 1"
          value={primitiveRadial1}
          onChange={e => {
            setPropertyState({
              address,
              key: 'primitiveRadial1',
              value: e.target.value
            })
          }}
        />
        <NumberField
          hideStepper
          size={sizePreference}
          min={0}
          step={0.001}
          aria-label="radial 2"
          value={primitiveRadial2}
          onChange={e => {
            setPropertyState({
              address,
              key: 'primitiveRadial2',
              value: e.target.value
            })
          }}
        />
        <NumberField
          hideStepper
          size={sizePreference}
          min={0}
          step={0.001}
          aria-label="radial 3"
          value={primitiveRadial3}
          onChange={e => {
            setPropertyState({
              address,
              key: 'primitiveRadial3',
              value: e.target.value
            })
          }}
        />
      </div>
    </div>
  )
}

const HorseshoeInputs: FC = () => {
  const { setPropertyState } = useSceneActions()

  const primitiveHorseshoeAngle = useSceneState('primitiveHorseshoeAngle')
  const address = useSceneState('address')
  const primitiveHorseshoeRadius = useSceneState('primitiveHorseshoeRadius')
  const primitiveHorseshoeWidth = useSceneState('primitiveHorseshoeWidth')
  const primitiveHorseshoeLength = useSceneState('primitiveHorseshoeLength')
  const primitiveHorseshoeThickness = useSceneState(
    'primitiveHorseshoeThickness'
  )
  const primitiveHorseshoeCorner = useSceneState('primitiveHorseshoeCorner')

  const sizePreference = useProjectState('sizePreference')

  return (
    <>
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={3.141}
        step={0.001}
        aria-label="Angle"
        label="Angle"
        value={primitiveHorseshoeAngle}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeAngle',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeAngle',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeAngle',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Radius"
        label="Radius"
        value={primitiveHorseshoeRadius}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeRadius',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeRadius',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeRadius',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Width"
        label="Width"
        value={primitiveHorseshoeWidth}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeWidth',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeWidth',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeWidth',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Length"
        label="Length"
        value={primitiveHorseshoeLength}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeLength',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeLength',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeLength',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Thickness"
        label="Thickness"
        value={primitiveHorseshoeThickness}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeThickness',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeThickness',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeThickness',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Corners"
        label="Corners"
        value={primitiveHorseshoeCorner}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeCorner',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeCorner',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeCorner',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
    </>
  )
}

const StarInputs: FC = () => {
  const { setPropertyState } = useSceneActions()

  const primitiveStarPoints = useSceneState('primitiveStarPoints')
  const address = useSceneState('address')
  const primitiveStarAngle = useSceneState('primitiveStarAngle')
  const starCorners = useSceneState('starCorners')

  const sizePreference = useProjectState('sizePreference')

  return (
    <>
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={3}
        max={12}
        step={1}
        aria-label="Points"
        label="Points"
        value={primitiveStarPoints}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarPoints',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarPoints',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarPoints',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.01}
        aria-label="Angle"
        label="Angle"
        value={primitiveStarAngle}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarAngle',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarAngle',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarAngle',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={starCorners.min}
        max={starCorners.max}
        aria-label="Corners"
        label="Corners"
        value={starCorners.value}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarCorners',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarCorners',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarCorners',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
    </>
  )
}

const FlatShape: FC = props => {
  const { setPropertyState } = useSceneActions()
  const primitiveType = useSceneState('primitiveType')
  const address = useSceneState('address')
  const hole = useSceneState('hole')

  const showRadialInputs = primitiveType === ScenePrimType.SOFT_BOX
  const showStarInputs = primitiveType === ScenePrimType.STAR
  const showHorseshoeInputs = primitiveType === ScenePrimType.HORSESHOE
  const showTriangleInputs = primitiveType === ScenePrimType.TRIANGLE

  const triangleVertex = useSceneState('triangleVertex')

  const sizePreference = useProjectState('sizePreference')

  return (
    <>
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={hole.min}
        max={hole.max}
        aria-label="Hole"
        label="Hole"
        value={hole.value}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHole',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHole',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHole',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      {showRadialInputs && <SoftBoxInputs {...props} />}
      {showStarInputs && <StarInputs {...props} />}
      {showHorseshoeInputs && <HorseshoeInputs {...props} />}
      {showTriangleInputs && (
        <PercentagePropertySlider
          label="Vertex"
          aria-label="Vertex"
          valueKey="triangleVertex"
          boundedValue={triangleVertex}
          min={-100}
          max={100}
        />
      )}
    </>
  )
}

export default memo(FlatShape)
